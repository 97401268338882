.icap_p{
    text-align: right;
}

@media screen and (max-width: 768px) {
    .icap_p{
        text-align: left;
        margin-left: 10%;
    }

    .go_to_demo_h1{
        font-size: 1.3rem;
    }
}