:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline--dark {
    background-color: transparent;
    color: #061A28FF;
    padding: 8px 20px;
    border: 1px solid #061A28FF;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover , .btn--large:hover {
    background-color: white;
    color: #061A28FF;
    transition: all 0.3s ease-out;
}

.btn--outline--dark:hover{
    background-color: #061A28FF;
    color: white;
    transition: all 0.3s ease-out;
}

.hover:hover{
    color: rgb(236,175,110);
}