.form-container {
    margin: 100px auto;
    width: 100%;

    position: relative;

    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
}

.close-btn {
    position: absolute;
    top: 2%;
    right: 3%;
    font-size: 1.5rem;
    z-index: 1;
    color: #fff;
    cursor: pointer;
}

.form-content-left {
    background: linear-gradient(
            90deg,
            #061A28FF 0%,
            rgb(101,122,151) 100%
    );
    border-radius: 10px 0 0 10px;
    position: relative;
}

.form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-img-2 {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
}

.form-content-right {
    width: 60%;
    background: rgba(101,122,151,0.2);
    border-radius: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    position: absolute;
}

.form-center-container {
    display: flex;
    justify-content: center;
    height: 70vh;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.form h1 {
    font-size: 1.5rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;

}

.form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
}

.form-inputs p {
    font-size: 0.8rem;
    margin-top: 0.1rem;
    color: #f00e0e;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: none;
    font-size: 1rem;
}

.form-input-message {
    display: block;
    padding-left: 10px;
    padding-top: 10px;
    outline: none;
    border-radius: 8px;
    width: 100%;
    border: none;
    resize: none;
    font-size: 1rem;
}


.btn--form {
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-bottom: 10%;
}


.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.form-input-btn {
    background-color: transparent;
    color:  #061A28FF;
    border: 1px solid  #061A28FF;
    transition: all 0.3s ease-out;
    width: 40%;
}

.form-input-btn:hover {
    cursor: pointer;
    background-color:  #061A28FF;
    color: white;
    transition: all 0.3s ease-out;
}

.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
}

.form-input-login a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
}

.box1 {
    flex: 4%;
}
.box2 {
    flex: 96%;
}

.checkbox {
    -webkit-appearance: none;
}

.checkbox::before {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: 1px solid rgb(101,122,151);
    background-color: #fff;
    display: block;
    content: "";
    float: left;
    margin-right: 5px;
    z-index: 5;
    position: relative;
}
.checkbox:checked::before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: rgb(101,122,151);
}


@media screen and (max-width: 960px ) {
    .box1 {
        flex: 8%;

    }
    .box2 {
        flex: 92%;

    }
    .form-content-right{
        width: 90%;
        height: 110%;
    }

    .form {
        width: 100%;
        height: 100vh;
    }


}