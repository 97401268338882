.img--woman {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/woman-g119ed996f_640.png');
    background-position: center;
    background-size: cover;
}

.img--man {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/man-gf718e5fbe_640.png');
    background-position: center;
    background-size: cover;
}

.img--marc {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/image_marc_bonin-andresen_2.jpg.webp');
    background-position: center;
    background-size: cover;
}

.img--sascha {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/image_sascha_johannes_2.jpg.webp');
    background-position: center;
    background-size: cover;
}

.img--thomas {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/image_thomas_haeupl_1.jpg.webp');
    background-position: center;
    background-size: cover;
}

.img--till {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/image_till_soerensen_2.jpg.webp');
    background-position: center;
    background-size: cover;
}

.img--bettina {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/bettina-johannes.1024x1024.jpg.webp');
    background-position: top;
    background-size: cover;
}

.img--rainer {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    background-image: url('/src/resources/images/image_rainer_seubert.jpg.webp');
    background-position: center;
    background-size: cover;
}