.container {
    width: 100%;
    height: 50vh;
    background-image: url("/src/resources/images/3d-low-poly-network-connections-background.jpg.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
}

.container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50vh;
    background-color: rgba(6,26,40,0.35);
}

.container h1 {
    color: white;
    position: relative;
}