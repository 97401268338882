@font-face {
    font-family: "space_monoregular";
    font-display: swap;
    src: local("space_monoregular"),
    url("./resources/fonts/Space_Mono/SpaceMono-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "pt_sans_regular";
    font-display: swap;
    src: local("pt_sans_regular"),
    url("./resources/fonts/PT_Sans/PTSans-Regular.ttf") format("truetype");
}



* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'pt_sans_regular', sans-serif;
    scroll-behavior: smooth;

}

h1,h2,h3,h4,h5,h6 {
    font-family: 'space_monoregular', monospace;
    font-variant-ligatures: none;
    color: #061A28FF;
}
p , li{
    color: #7a7b7f;
}

a {
    text-decoration-line: none;
    font-style: italic;
    color: rgb(101,122,151);
}

a:hover {
    color: rgb(236,175,110);
}

.line {
    background: rgb(236,175,110);
    width: 150px;
    height: 5px;
    margin-bottom: 10px !important;
}

.cookie-container{
    height: 20vh;
    background-color: rgb(101,122,151);
    color: white;
}