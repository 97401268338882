.container--intro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container--example{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.container--column--align-left{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.container--column--align-right{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.container-column--align--left--width50{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.pl-100{
    padding-left: 100px;
}

.container--row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.grid--item--img{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.img {
    width: 60%;
}

.container--intro p , .container--intro h1 , .container--intro h3 {
    width: 60%;
}

ul{
    width: 55%;
}

.icap-img{

    border-radius: 8px;
    padding: 5px;
    width: 100%;

}

.img-container{
    width:100%;
    height:auto;
    display:flex;
    justifyContent:center;
    alignItems:center;
    overflow: hidden;
    padding-left: 5%;
    padding-right: 5%
}

.txt-container{
    display:flex;
    justifyContent:center;
    alignItems:center;
}

.txt-wrapper-70{
    width: 70%;
    height: 70%;
}

.txt-container .txt-container-column{
    display:flex;
    justifyContent:center;
    alignItems:flex-start;
    flex-direction: column;
}


.body-demo{
    background-image: url("../../SVG/about-shape-1.svg"), url("../../SVG/about-shape-2.svg"),url("../../SVG/about-shape-1.svg"), url("../../SVG/about-shape-2.svg");
    background-position:
            top right,
            top 40% left,
            bottom 40% right 10px,
            bottom  left;
    background-size: inherit;
    background-repeat: no-repeat;
}


@media screen and (max-width: 960px){
    .container--intro p , .container--intro h1 , .container--intro h3{
        width: 90%;
    }

    ul{
        width: 80%;

    }
    .container--column--align-left , .container-column--align--left--width50 {
        width: 90%;
    }

    .img-container{
        padding-left: 1%;
        padding-right: 1%
    }

    .txt-container-column{
        padding-left: 10%;
    }

}