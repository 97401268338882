.grid--item{
    width: 100%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.map {
    filter: grayscale(70%);
}


@media screen and (max-width: 768px) {
    .about_bioretis{
        margin-left: 10%;
    }
}


