.hero-container {
    background: url('../../resources/images/3d-low-poly-network-connections-background.jpg.webp') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    margin-top: -80px;
}

.hero-container::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(6,26,40,0.35);
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    margin-left: 10%;
    position: relative;
}

.hero-container > p {
    position: relative;
    margin-top: 8px;
    width: 50%;
    color: #fff;
    font-size: 2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    margin-left: 10%;
}

.hero-btns {
    position: relative;
    margin-top: 32px;
    margin-left: 10%;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

.slogan{
    position: relative;
    margin-top: 8px;
    width: 50%;
    color: #fff;
    font-size: 2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    margin-left: 10%;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }


}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 50px;
        margin-top: 0;
    }

    .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .slogan{
        display: none;
    }

    .hero-container {
        margin-top: -160px;
    }
}