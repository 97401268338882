.cont{
    width: 100%;
    height: 100vh;
    overflow: hidden;

}
.left-nav{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.publications {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-right: 10%;
    padding-top: 100px;
}

#sidebar.affix {
    top: 95px;
}

.bs-docs-sidebar {
    margin-top: 100px;
    margin-bottom: 20px;
}

.nav {
    list-style-type: none;
}

.justify {
    text-align: justify;
    margin-bottom: 30px;
}

.bs-docs-sidebar .nav>li>a {
    color: rgb(101,122,151);
    border-left: 2px solid transparent;
    padding: 4px 20px;
    font-weight: 400;
    font-size: 1.2rem;
}

.bs-docs-sidebar .nav .nav>li>a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 1rem;

}

.bs-docs-sidebar .nav>.active>a,
.bs-docs-sidebar .nav>li>a:hover,
.bs-docs-sidebar .nav>li>a:focus {
    color: #061A28FF;
    text-decoration: none;
    background-color: transparent;
    border-left-color: rgb(236,175,110);

}

h2 span {
    font-family: 'space_monoregular', monospace;
}

.bs-docs-sidebar .nav>.active>a,
.bs-docs-sidebar .nav>.active:hover>a,
.bs-docs-sidebar .nav>.active:focus>a {
    font-weight: 700;
}

.bs-docs-sidebar .nav .nav>.active>a,
.bs-docs-sidebar .nav .nav>.active:hover>a,
.bs-docs-sidebar .nav .nav>.active:focus>a {
    font-weight: 500;
}

.bs-docs-sidebar .nav ul.nav {
    display: none;
}

.bs-docs-sidebar .nav>.active>ul.nav {
    display: block;
}

.group, .subgroup {
    padding-top: 100px;
    margin-top: -100px;
    margin-left: 10%;
}

.timeline-item_publikationen {
    margin-bottom: 10px;
    padding-left: 0px;
}

.timeline-item_publikationen h2 {
    font-family: 'space_monoregular', monospace;
    font-size: 2rem;
    line-height: 42px;
    color: rgb(101,122,151);
}



@media screen and (max-width: 960px) {
    .left-nav{
        display: none;
    }
}